import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Config from "../views/Config.vue";
import About from "../views/About.vue";

type IDRouteRecordRaw = RouteRecordRaw & {
  id: number;
};

export const routes: Array<IDRouteRecordRaw> = [
  {
    id: 1,
    path: "/",
    name: "Startseite",
    component: About,
    meta: {
      icon: "trash",
    },
  },
  {
    id: 2,
    path: "/config",
    name: "Einstellung",
    component: Config,
    meta: {
      icon: "sliders-h",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
