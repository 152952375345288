
import { Options, Vue } from "vue-class-component";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import moment from "moment";
import { TEXTCOLOR } from "@/helper/utils";

class TrashItem {
  _id?: string;
  date!: Date;
  color!: string;
  size!: string;
}

@Options({
  name: "TrashCard",
  components: {
    FontAwesomeIcon,
  },
  props: TrashItem,
})
export default class TrashCard extends Vue.with(TrashItem) {
  get icon(): string {
    if (this.color === "tree") return "tree";
    return this.size === "bin" ? "trash" : "dumpster";
  }

  get daysLeft(): string {
    const duration = moment
      .duration({ to: moment(this.date).add(1, "d"), from: new Date() })
      .asDays()
      .toFixed(0);
    switch (duration) {
      case "0":
        return "HEUTE!";
      case "1":
        return "Morgen!";
      default:
        return `In ${duration} Tagen!`;
    }
  }

  get weekday(): string {
    const dayNumber = moment(this.date).isoWeekday();
    switch (dayNumber) {
      case 1:
        return "Montag";
      case 2:
        return "Dienstag";
      case 3:
        return "Mittwoch";
      case 4:
        return "Donnerstag";
      case 5:
        return "Freitag";
      case 6:
        return "Samstag";
      case 7:
        return "Sonntag";
      default:
        return "";
    }
  }

  get textWarningColor(): string {
    if (this.daysLeft === "HEUTE!") return "text-red-700 font-bold";
    if (this.daysLeft === "Morgen!") return "text-yellow-600";
    return "";
  }

  get textColor(): TEXTCOLOR {
    switch (this.color) {
      case "yellow":
        return TEXTCOLOR.YELLOW;
      case "gray":
        return TEXTCOLOR.GRAY;
      case "green":
        return TEXTCOLOR.GREEN;
      case "blue":
        return TEXTCOLOR.BLUE;
      case "red":
        return TEXTCOLOR.RED;
      case "brown":
        return TEXTCOLOR.BROWN;
      case "tree":
        return TEXTCOLOR.GREEN;
      default:
        return TEXTCOLOR.GRAY;
    }
  }
}
