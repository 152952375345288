<template>
  <div class="flex flex-col min-h-screen">
    <NavBar />
    <router-view class="flex-grow" />
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";

const App = defineComponent({
  components: {
    NavBar,
    Footer,
  },
});

export default App;
</script>
