export enum TEXTCOLOR {
  YELLOW = "text-yellow-300",
  GRAY = "text-gray-700",
  GREEN = "text-green-700",
  BLUE = "text-blue-700",
  RED = "text-red-700",
  BROWN = "text-brown",
}

interface ConfigObject {
  brownTrash: SingleConfigObject;
  brownDumpster: SingleConfigObject;
  redTrash: SingleConfigObject;
  redDumpster: SingleConfigObject;
  greenTrash: SingleConfigObject;
  greenDumpster: SingleConfigObject;
  yellowTrash: SingleConfigObject;
  yellowDumpster: SingleConfigObject;
  grayTrash: SingleConfigObject;
  grayDumpster: SingleConfigObject;
  blueTrash: SingleConfigObject;
  blueDumpster: SingleConfigObject;
  tree: SingleConfigObject;
}

interface SingleConfigObject {
  id: number;
  textColor: TEXTCOLOR;
  type: string;
  value: boolean;
}

interface TrashQuery {
  red?: "bin" | "container" | "both";
  blue?: "bin" | "container" | "both";
  yellow?: "bin" | "container" | "both";
  brown?: "bin" | "container" | "both";
  green?: "bin" | "container" | "both";
  gray?: "bin" | "container" | "both";
  tree?: "bin" | "container" | "both";
}

const formData = {
  brownTrash: {
    id: 11,
    textColor: TEXTCOLOR.BROWN,
    type: "trash",
    value: false,
  },
  brownDumpster: {
    id: 12,
    textColor: TEXTCOLOR.BROWN,
    type: "dumpster",
    value: false,
  },
  redTrash: {
    id: 21,
    textColor: TEXTCOLOR.RED,
    type: "trash",
    value: false,
  },
  redDumpster: {
    id: 22,
    textColor: TEXTCOLOR.RED,
    type: "dumpster",
    value: false,
  },
  greenTrash: {
    id: 31,
    textColor: TEXTCOLOR.GREEN,
    type: "trash",
    value: false,
  },
  greenDumpster: {
    id: 32,
    textColor: TEXTCOLOR.GREEN,
    type: "dumpster",
    value: false,
  },
  yellowTrash: {
    id: 41,
    textColor: TEXTCOLOR.YELLOW,
    type: "trash",
    value: false,
  },
  yellowDumpster: {
    id: 42,
    textColor: TEXTCOLOR.YELLOW,
    type: "dumpster",
    value: false,
  },
  grayTrash: {
    id: 51,
    textColor: TEXTCOLOR.GRAY,
    type: "trash",
    value: false,
  },
  grayDumpster: {
    id: 52,
    textColor: TEXTCOLOR.GRAY,
    type: "dumpster",
    value: false,
  },
  blueTrash: {
    id: 61,
    textColor: TEXTCOLOR.BLUE,
    type: "trash",
    value: false,
  },
  blueDumpster: {
    id: 62,
    textColor: TEXTCOLOR.BLUE,
    type: "dumpster",
    value: false,
  },
  tree: {
    id: 71,
    textColor: TEXTCOLOR.GREEN,
    type: "tree",
    value: false,
  },
};

export const SaveToConfig = ({
  blue,
  brown,
  gray,
  green,
  red,
  tree,
  yellow,
}: TrashQuery) => {
  formData.blueDumpster.value = isDumpster(blue);
  formData.blueTrash.value = isBin(blue);
  formData.brownDumpster.value = isDumpster(brown);
  formData.brownTrash.value = isBin(brown);
  formData.grayDumpster.value = isDumpster(gray);
  formData.grayTrash.value = isBin(gray);
  formData.greenDumpster.value = isDumpster(green);
  formData.greenTrash.value = isBin(green);
  formData.redDumpster.value = isDumpster(red);
  formData.redTrash.value = isBin(red);
  formData.yellowDumpster.value = isDumpster(yellow);
  formData.yellowTrash.value = isBin(yellow);
  formData.tree.value = isBin(tree);
  return formData;
};

const isDumpster = (value: "bin" | "container" | "both" | undefined) => {
  return value === "container" || value === "both";
};

const isBin = (value: "bin" | "container" | "both" | undefined) => {
  return value === "bin" || value === "both";
};

export const configToSave = (configObj: ConfigObject | any) => {
  const result = {} as TrashQuery;
  let convertion = singleConfigToSave(
    "blue",
    configObj.blueTrash,
    configObj.blueDumpster
  );
  if (convertion) {
    result.blue = convertion;
  }
  convertion = singleConfigToSave(
    "red",
    configObj.redTrash,
    configObj.redDumpster
  );
  if (convertion) {
    result.red = convertion;
  }
  convertion = singleConfigToSave(
    "yellow",
    configObj.yellowTrash,
    configObj.yellowDumpster
  );
  if (convertion) {
    result.yellow = convertion;
  }
  convertion = singleConfigToSave(
    "brown",
    configObj.brownTrash,
    configObj.brownDumpster
  );
  if (convertion) {
    result.brown = convertion;
  }
  convertion = singleConfigToSave(
    "green",
    configObj.greenTrash,
    configObj.greenDumpster
  );
  if (convertion) {
    result.green = convertion;
  }
  convertion = singleConfigToSave(
    "gray",
    configObj.grayTrash,
    configObj.grayDumpster
  );
  if (convertion) {
    result.gray = convertion;
  }
  convertion = singleConfigToSave("tree", configObj.tree, null);
  if (convertion) {
    result.tree = convertion;
  }
  return result;
};

const singleConfigToSave = (
  inputColor: string,
  configTrash: SingleConfigObject | null,
  configDump: SingleConfigObject | null
): "both" | "bin" | "container" | null => {
  if (configTrash?.value && configDump?.value) return "both";
  else if (configTrash?.value) return "bin";
  else if (configDump?.value) return "container";
  else return null;
};
