
import { defineComponent } from "vue";
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";

const App = defineComponent({
  components: {
    NavBar,
    Footer,
  },
});

export default App;
