
import { Options, Vue } from "vue-class-component";
import HelloWorld from "@/components/HelloWorld.vue";
import ToggleSwitch from "@/components/ToggleSwitch.vue"; // @ is an alias to /src
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { configToSave, SaveToConfig } from "@/helper/utils";
import { useStore } from "vuex";

@Options({
  components: {
    HelloWorld,
    ToggleSwitch,
    FontAwesomeIcon,
  },
  name: "ConfigView",
})
export default class Home extends Vue {
  store = useStore();
  formData = {} as any;
  showAlert = false;

  updateFormData(e: any) {
    const foundKey = Object.keys(this.formData).find(
      (key) => this.formData[key].id === e.id
    );
    if (foundKey) {
      this.formData[foundKey] = e;
    }
    this.handleSave();
  }

  mounted(): void {
    this.formData = SaveToConfig(this.store.getters.getConfig);
  }

  handleSave = (): void => {
    this.store.dispatch("setConfig", configToSave(this.formData));
  };
}
