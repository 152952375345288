
import { Options, Vue } from "vue-class-component";
import { routes } from "@/router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

@Options({
  name: "Navbar",
  components: {
    FontAwesomeIcon,
  },
})
export default class NavBar extends Vue {
  isOpen = false;

  get routes() {
    return routes;
  }

  mounted() {
    this.$router.beforeResolve(() => {
      this.isOpen = false;
    });
  }
}
