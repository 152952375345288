
import { Options, Vue } from "vue-class-component";

@Options({
  name: "ToggleSwitch",
  emits: ["update"],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
})
export default class Home extends Vue {
  toggleSwitch(item: Record<string, any>): void {
    this.$emit("update", { ...item, value: !item.value });
  }
}
