<template>
  <div class="mt-6 text-sm text-right">© Dominik Barczewski</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

const Footer = defineComponent({});

export default Footer;
</script>
