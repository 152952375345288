<template>
  <div class="flex flex-col">
    <h1 class="text-6xl text-gray-400 mb-10">Einstellungen</h1>
    <div class="grid grid-cols-3 gap-4 mx-2">
      <div v-for="item in formData" :key="item.id">
        <ToggleSwitch :item="item" @update="updateFormData"
          ><FontAwesomeIcon
            :class="item.textColor"
            :icon="['fas', item.type]"
            size="4x"
        /></ToggleSwitch>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HelloWorld from "@/components/HelloWorld.vue";
import ToggleSwitch from "@/components/ToggleSwitch.vue"; // @ is an alias to /src
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { configToSave, SaveToConfig } from "@/helper/utils";
import { useStore } from "vuex";

@Options({
  components: {
    HelloWorld,
    ToggleSwitch,
    FontAwesomeIcon,
  },
  name: "ConfigView",
})
export default class Home extends Vue {
  store = useStore();
  formData = {} as any;
  showAlert = false;

  updateFormData(e: any) {
    const foundKey = Object.keys(this.formData).find(
      (key) => this.formData[key].id === e.id
    );
    if (foundKey) {
      this.formData[foundKey] = e;
    }
    this.handleSave();
  }

  mounted(): void {
    this.formData = SaveToConfig(this.store.getters.getConfig);
  }

  handleSave = (): void => {
    this.store.dispatch("setConfig", configToSave(this.formData));
  };
}
</script>
