import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/style/tailwind.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTrash,
  faDumpster,
  faTree,
  faSlidersH,
} from "@fortawesome/free-solid-svg-icons";

library.add(faTrash, faDumpster, faTree, faSlidersH);

createApp(App).use(store).use(router).mount("#app");
