
import { Options, Vue } from "vue-class-component";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import TrashCard from "@/components/TrashCard.vue";
import { useStore } from "vuex";
import LoadingIcon from "@/components/LoadingIcon.vue";

interface TrashItem {
  _id: string;
  date: Date;
  color: string;
  size: "container" | "bin";
}

@Options({
  name: "About",
  components: {
    FontAwesomeIcon,
    TrashCard,
    LoadingIcon,
  },
})
export default class About extends Vue {
  trashList = [] as TrashItem[];
  loading = true;
  store = useStore();

  loadingSVG = require("./../assets/Loading.svg");

  async mounted() {
    const unsortedList = (await this.store.dispatch("setTrash")) as TrashItem[];
    this.trashList = unsortedList.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    this.loading = false;
  }
}
