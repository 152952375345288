<template>
  <button
    class="
      shadow
      px-10
      text-center
      border-2
      rounded
      flex
      justify-center
      items-center
      w-full
      h-full
      outline-none
      focus:outline-none
    "
    :class="`${item?.value ? 'border-green-400' : 'border-red-400'} `"
    style="aspect-ratio: 1"
    @click="toggleSwitch(item)"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  name: "ToggleSwitch",
  emits: ["update"],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
})
export default class Home extends Vue {
  toggleSwitch(item: Record<string, any>): void {
    this.$emit("update", { ...item, value: !item.value });
  }
}
</script>
