<template>
  <nav
    class="
      flex
      w-full
      items-center
      justify-between
      px-6
      h-16
      bg-white
      text-gray-700
      border-b border-gray-200
      z-10
    "
  >
    <div class="flex items-center w-full">
      <button class="mr-2" aria-label="Open Menu" @click="isOpen = true">
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          class="w-8 h-8"
        >
          <path d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
      </button>
      <div class="w-full text-right">
        <h1 class="text-gray-600 text-2xl">Müll - Otto-Martin-Straße</h1>
      </div>
    </div>

    <transition
      enter-class="opacity-0"
      enter-active-class="ease-out transition-medium"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-active-class="ease-out transition-medium"
      leave-to-class="opacity-0"
    >
      <div
        @keydown.esc="isOpen = false"
        v-show="isOpen"
        class="z-10 fixed inset-0 transition-opacity"
      >
        <div
          @click="isOpen = false"
          class="absolute inset-0 bg-black opacity-50"
          tabindex="0"
        ></div>
      </div>
    </transition>
    <aside
      class="
        transform
        top-0
        left-0
        w-64
        bg-white
        fixed
        h-full
        overflow-auto
        ease-in-out
        transition-all
        duration-300
        z-30
      "
      :class="isOpen ? 'translate-x-0' : '-translate-x-full'"
    >
      <div class="mx-2">
        <div class="p-2 text-3xl border-b-2 select-none">Navigation</div>
        <router-link
          v-for="route in routes"
          class="
            bg-gray-100
            my-2
            p-2
            text-2xl
            select-none
            space-x-2
            block
            rounded
          "
          :key="route.id"
          :to="route.path"
          active-class="bg-gray-300"
        >
          <FontAwesomeIcon :icon="['fas', route.meta.icon]"></FontAwesomeIcon>
          <span>{{ route.name }}</span>
        </router-link>
      </div>
    </aside>
  </nav>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { routes } from "@/router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

@Options({
  name: "Navbar",
  components: {
    FontAwesomeIcon,
  },
})
export default class NavBar extends Vue {
  isOpen = false;

  get routes() {
    return routes;
  }

  mounted() {
    this.$router.beforeResolve(() => {
      this.isOpen = false;
    });
  }
}
</script>
