import { createStore } from "vuex";
import axios from "axios";
import moment from "moment";

export default createStore({
  state: {
    config: {
      red: "bin",
      blue: "bin",
      yellow: "bin",
      brown: "",
      green: "",
      gray: "",
      tree: "bin",
    },
    trash: [],
  },
  mutations: {
    setConfig: (state, value) => {
      localStorage.setItem("config", JSON.stringify(value));
      state.config = value;
    },
    setTrash: (state, value) => {
      state.trash = value;
    },
  },
  getters: {
    getConfig: (state) => {
      const config = localStorage.getItem("config");
      if (config) return JSON.parse(config);
      return state.config;
    },
    getTrash: (state) => {
      return state.trash;
    },
  },
  actions: {
    setConfig: ({ commit }, payload) => {
      commit("setConfig", payload);
    },
    setTrash: async ({ commit, getters }) => {
      const date = moment(new Date()).subtract(1, "d").format("YYYY-MM-DD");
      const response = await axios.get(
        "https://api.barczewski.net/trash/filter",
        {
          params: { date, ...getters.getConfig },
        }
      );
      commit("setTrash", response.data);
      return response.data;
    },
  },
  modules: {},
});
